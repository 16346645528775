import appConfig from "configs/app.config";
import ApiService from "services/ApiService";

export default class Index {
  static async fetchData(options) {
    return ApiService.fetchData({
      url: `${appConfig.servicePrefix[appConfig.stage].auth}/user`,
      ...options,
    });
  }

  static async login(data) {
    return ApiService.fetchData({
      url: `${appConfig.servicePrefix[appConfig.stage].auth}/user/login`,
      method: "post",
      data,
    });
  }

  static async register_staff(data) {
    return ApiService.fetchData({
      url: `${
        appConfig.servicePrefix[appConfig.stage].auth
      }/user/register_staff`,
      method: "post",
      data,
    });
  }

  static async read(params) {
    return this.fetchData({
      method: "get",
      params,
    });
  }

  static async create(data) {
    return this.fetchData({
      method: "post",
      data,
    });
  }

  static async update(data) {
    return this.fetchData({
      method: "put",
      data,
    });
  }

  static async delete(data) {
    return this.fetchData({
      method: "delete",
      data,
    });
  }
}
